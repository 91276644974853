window.addEventListener('load', (event) => {
  document.querySelectorAll('.menu-approach').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('menu:approach clicks');
    });
  });
  document.querySelectorAll('.menu-bookme').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('calendly clicks');
    });
  });
  document.querySelectorAll('.menu-mediakit').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('media kit downloads');
    });
  });
  document.querySelectorAll('.menu-services').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('menu:services clicks');
    });
  });
  document.querySelectorAll('.social-linkedin').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('social:linkedin clicks');
    });
  });
  document.querySelectorAll('.social-x').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('social:x clicks');
    });
  });
  document.querySelectorAll('.social-itunes').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('social:itunes clicks');
    });
  });
});
